import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import Alert from '../../../components/Alert/Alert';

const ConsumerHubPage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			eventImg: file(relativePath: { eq: "resourceHub/consumerWebinarJune2024.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			header={<span className='bold'>Consumer Education: Resource Hub</span>}
			breadcrumb={{
				text: 'Research & Education: Resource Hub',
				path: '/about/research-and-education-program/research-education-resource-hub/'
			}}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.homeDescription'
				})}
				lang={intl.locale}
				title='Consumer Education: Resource Hub'
			/>
			<Container>
				{intl.locale === 'fr' && (
					<Row align='stretch'>
						<Col>
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						</Col>
					</Row>
				)}

				<Row>
					<Col>
						<p>
							In cooperation with consumers and consumer organizations, the HCRA is dedicated to
							developing and promoting educational resources that support consumer protection, education,
							and awareness regarding homebuying and homeownership.
						</p>
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<h3>Keys to Comfort: Info for New Homebuyers and Owners</h3>
						<a href='https://www.youtube.com/watch?v=YwOYhccDJdA'>
							<h4>Watch the Webinar</h4>
						</a>
					</Col>
					<Col md={6}>
						<div style={{ border: `1.5px solid #CCC` }}>
							<Img fluid={data.eventImg.childImageSharp.fluid} alt='' />
						</div>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default ConsumerHubPage;
